import { Helmet } from 'react-helmet-async';
import {useNavigate} from 'react-router-dom';
// @mui
import {
    Card,
    Container,
    Box, Grid, Stack, Typography,
    MenuItem, TextField,
    SelectChangeEvent
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
import { useSettingsContext } from '../../components/settings';
// sections
import {useMutation, useQuery} from "@apollo/client";
import FormProvider, {RHFSelect, RHFTextField} from "../../components/hook-form";
import {LoadingButton } from "@mui/lab";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {useSnackbar} from "notistack";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {CREATE_BAND} from "../../api/gql/mutations/CreateBand";
import {GET_BANDS} from "../../api/gql/queries/GetBands";
import { GoogleMap } from 'src/components/google-map/GoogleMap';
import { GoogleMapMountPoint } from 'src/components/google-map/GoogleMapMountPoint';
import { mount } from 'src/components/google-map/mount-google-map';
import React, {useState} from "react";
import {DefaultMapLocation} from "../../constants";
import {GOOGLE_MAPS_API_KEY} from "../../config-global";
import AutoComplete from "../../components/google-map-autocomplete/Autocomplete";
import { GET_PARTY_TYPES } from 'src/api/gql/queries/GetPartyTypes';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { INSERT_OFFER_MANUAL_MANAGER } from 'src/api/gql/mutations/InsertOfferManualManager';

import moment from "moment";
import "moment/locale/pl";

type FormValuesProps = {
    client_name: string;
    client_surname: string;
    client_email: string;
    client_phone: string;
    price: string;
};


moment.locale("pl");

moment.updateLocale("pl", {
    week: {
        dow: 1
    }
});

export default function OffersAddManualPage() {
    const { themeStretch } = useSettingsContext();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [bandId, setBandId] = useState("");
    const [partyTypeId, setPartyTypeId] = useState("");
    const { loading: bandsLoading, error: bandsError, data: bandsData } = useQuery(GET_BANDS);
    const { loading: partyTypesLoading, error: partyTypesError, data: partyTypesData } = useQuery(GET_PARTY_TYPES, { variables: { bandId: bandId } });
    const [insertOffer, { data: insertOfferData, loading: insertOfferLoading, error: insertOfferError }] = useMutation(INSERT_OFFER_MANUAL_MANAGER);
    const [selectedDate, setSelectedDate] = useState("");

    const [location, setLocation] = useState(DefaultMapLocation);

    const [formattedAddress, setFormattedAddress] = useState("");

    const [addressErrorForm, setAddressErrorForm] = useState<string | null>(null);

    const [createBand] = useMutation(CREATE_BAND, {
        refetchQueries: [
            {query: GET_BANDS},
            'GetBands'
        ],
    });

    const UpdateUserSchema = Yup.object().shape({
        client_name: Yup.string().required('Imię Klienta jest wymagana'),
        client_surname: Yup.string().required('Nazwisko Klienta jest wymagane'),
        client_email: Yup.string().required('Email Klienta jest wymagany'),
        client_phone: Yup.string().required('Telefon Klienta jest wymagany'),
        price: Yup.string().required('Cena jest wymagany'),
    });

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(UpdateUserSchema),
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    
   const formatDate = (date: string | null) => {
        if(!date) {
            return '';
        }
        const newDate = new Date(date);
        return `${newDate.getFullYear()}/${(newDate.getMonth()+1)}/${newDate.getDate()}`;
    }



    const onSubmit = async (formData: FormValuesProps) => {
        try {
            if (bandId?.length === 0) {
                setAddressErrorForm("Nie wybrano zespołu")
                return null;
            }
            if (partyTypeId?.length === 0) {
                setAddressErrorForm("Nie wybrano typu imprezy")
                return null;
            }
            if (selectedDate?.length === 0) {
                setAddressErrorForm("Nie wybrano daty imprezy")
                return null;
            }
            if (formattedAddress?.length === 0) {
                setAddressErrorForm("Adres nie może być pusty")
                return null;
            }
            let addedOffer = await insertOffer({ variables: {
                bandId: bandId,
                partyCity: "",
                partyAddress: formattedAddress,
                date: formatDate(selectedDate),
                partyTypeId: partyTypeId,
                phone: formData.client_phone,
                name: formData.client_name,
                surname: formData.client_surname,
                email: formData.client_email,
                price: formData.price,
                status: "manual"
            }});
            enqueueSnackbar('Pomyślnie dodano ofertę!');
            navigate(PATH_DASHBOARD.general.offersDetails(addedOffer.data.insert_offers.returning[0].id))
        } catch (error) {
            console.error(error);
            enqueueSnackbar('Wystąpił błąd dodawania oferty. Proszę sprawdzić poprawność wprowadzonych danych i odświeżyć strone!', {
                variant: 'error',
                persist: false
            })
        }
    };

    const handleChangeBand = (event: SelectChangeEvent) => {
        setBandId(event.target.value as string);
    };
    const handleChangePartyType = (event: SelectChangeEvent) => {
        setPartyTypeId(event.target.value as string);
    };

    return (
        <>
            <Helmet>
                <title> Party Manager | Dodaj Ofertę</title>
            </Helmet>

            <Container maxWidth={themeStretch ? false : 'lg'}>
                {/* TODO: Set heading to plural/singular depending on amount of bands */}
                <CustomBreadcrumbs
                    heading="Nowa Oferta"
                    links={[
                        { name: 'Dashboard', href: PATH_DASHBOARD.root },
                        { name: 'Oferty', href: PATH_DASHBOARD.general.reservations },
                        { name: 'Nowa Oferta' },
                    ]}
                />
                <Box>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                <Card sx={{ p: 3 }}>
                                    <Box
                                        rowGap={3}
                                        columnGap={2}
                                        display="grid"
                                        gridTemplateColumns={{
                                            xs: 'repeat(1, 1fr)',
                                            sm: 'repeat(2, 1fr)',
                                        }}
                                    >
                                        <RHFSelect name="bands" label="Zespół" placeholder="Zespół" onChange={handleChangeBand}>
                                            {/*<option value='' /> TODO: Set empty default */}
                                            {bandsData?.bands.map((band: { name: string, id: string }) => (
                                                <MenuItem key={band.id} value={band.id}>
                                                    {band.name}
                                                </MenuItem>
                                          
                                            ))}
                                        </RHFSelect>

                                        <RHFSelect name="bandPartyType" label="Typ imprezy" placeholder="Typ imprezy" onChange={handleChangePartyType}>
                                            {/*<option value='' /> TODO: Set empty default */}
                                            {partyTypesData?.party_types.map((partyType: { name: string, id: string }) => (
                                                <MenuItem key={partyType.id} value={partyType.id}>
                                                    {partyType.name}
                                                </MenuItem>
                                          
                                            ))}
                                        </RHFSelect>

                                        <RHFTextField name="client_name" label="Imię klienta" />
                                        <RHFTextField name="client_surname" label="Nazwisko klienta" />
                                        <RHFTextField name="client_email" label="Email klienta" />
                                        <RHFTextField name="client_phone" label="Telefon klienta" />
                                        <RHFTextField name="price" label="Kwota" />

                                        <RHFSelect name="bands" label="Stan Oferty" placeholder="Stan Oferty">
                                            {/*<option value='' /> TODO: Set empty default */}
                                                <MenuItem key="1" value="1">
                                                    Oferta Zakceptowana i umowa podpisana - Automatyczna wysyłka samej ankiety
                                                </MenuItem>
                                        </RHFSelect>

                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                InputProps={{
                                                    autoComplete: 'off',
                                                }}
                                                toolbarFormat="pl-pl"
                                                label="Data imprezy"
                                                value={selectedDate}
                                                //firstDayOfWeek="monday"
                                                onChange={(newValue) => {
                                                    if (newValue) {
                                                        setSelectedDate(newValue)
                                                    }
                                                }}
                                                renderInput={(params) => <TextField {...params} name="date"/>}
                                            />
                                        </LocalizationProvider>
                                        <div>
                                            <AutoComplete
                                                getPlaceDetails={(formatted_address, lat, lng) => {
                                                    setLocation({ lat, lng})
                                                    setFormattedAddress(formatted_address)
                                                    setAddressErrorForm(null)
                                                }}
                                                placeholder='Dokładny adres miejsca imprezy'
                                            />
                                            {addressErrorForm && <p style={{color: 'red', margin: 0, fontSize: '0.75rem'}}>{addressErrorForm}</p>}
                                        </div>
                                    </Box>
                                    <Box
                                        sx={{ mt: 4 }}
                                    >
                                        <Typography variant="h5" component="h5" sx={{ mx: 0.25 }}>Lokalizacja</Typography>
                                        <GoogleMap
                                            apiKey={GOOGLE_MAPS_API_KEY}
                                            fallback={<img src='fallback' />}
                                            mountPoint={
                                                <GoogleMapMountPoint
                                                    //TODO: Refactor so that default values are constant and not changing when changing marker location - it's causing map to re-render each time
                                                    options={{zoom: 11, center: {lng: location.lng, lat: location.lat}}}
                                                    mount={mount}
                                                />
                                            }
                                        />
                                    </Box>
                                    <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                                        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                                            Dodaj
                                        </LoadingButton>
                                    </Stack>
                                </Card>
                            </Grid>
                        </Grid>
                    </FormProvider>
                </Box>
            </Container>
        </>
    );
}

