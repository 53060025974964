import {gql} from "@apollo/client";

export const INSERT_OFFER_MANUAL_MANAGER = gql`
mutation InsertOfferManualManager($bandId: uuid!, $partyCity: String!, $partyAddress: String!, $date: date!, $partyTypeId: uuid!, $phone: String!, $name: String!, $surname: String!, $email: String!, $price: money!, $status: String!) {
  insert_offers(objects: {source: "", band_id: $bandId, party_address: $partyAddress, party_city: $partyCity, party_date: $date, phone: $phone, name: $name, surname: $surname, email: $email, party_type_id: $partyTypeId, price: $price, status: $status}) {
    affected_rows
    returning {
      id
    }
  }
}
`;