import { gql } from "@apollo/client";

export const GET_SURVEY_TEMPLATE_BY_ID = gql`
query GetSurveyTemplates($id: uuid!) {
  survey_templates(where: {id:{_eq: $id}}) {
    lock_edit_before_days
		send_before_days
		email_template
		email_title
		template
		created_at
		updated_at
		id
		party_type_id
  }
}
`;
