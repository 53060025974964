import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import {Typography, Box, Link, BoxProps, Grid} from '@mui/material';
import Image from '../image';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
        <Grid sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
        }}>
            <Image
                alt="logo"
                src="/assets/illustrations/logo_default.png"
                sx={{
                    width: 40,
                    height: 40,
                    display: 'inline-flex',
                    ...sx,
                }}
            />
            <Typography variant="h6" component="span" sx={{ mx: 0.25 }}>
                Party Manager
            </Typography>
        </Grid>
    );

    if (disabledLink) {
      return logo;
    }

    return (
        <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
         <Grid sx={{
             display: 'flex',
             alignItems: 'center',
             justifyContent: 'flex-start'
         }}>
             <Image
              alt="logo"
              src="/assets/illustrations/logo_default.png"
              sx={{
                width: 40,
                height: 40,
                display: 'inline-flex',
                ...sx,
                  }}
                />
              <Typography variant="h6" component="span" sx={{ mx: 0.25 }}>
              Party Manager
              </Typography>
          </Grid>
     </Link>

    );
  }
);

export default Logo;
