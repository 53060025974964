import { PropsWithChildren, useEffect } from "react";
import { useAuthContext } from "./auth/useAuthContext";
import Tracker from '@openreplay/tracker';

const OPEN_REPLAY_KEY = process.env.REACT_APP_OPEN_REPLAY_KEY || '';

const tracker = new Tracker({
  projectKey: OPEN_REPLAY_KEY,  
});

export default function OpenReplayProvider({ children }: PropsWithChildren) {
    const { user } = useAuthContext();
    useEffect(() => {
        tracker.start({
            userID: user?.email,
        });
        tracker.setUserID(user?.email);
        return () => {
          tracker.stop();
        };
      }, [user]);

    return <>{children}</>;
}