import * as React from 'react';

import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, split} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import {setContext} from "@apollo/client/link/context";
import {useAuthContext} from "../../auth/useAuthContext";
import { HOST_API, WS_API } from '../../config-global';
//import { WebSocketLink } from "@apollo/client/link/ws";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
type ApolloTokenProviderProps = {
    children: React.ReactNode,
}

export default function ApolloTokenProvider({ children }: ApolloTokenProviderProps) {
    const { user } = useAuthContext();

    const httpLink = createHttpLink({
        uri: HOST_API,
    });
    const token = user?.accessToken;

    const authLink = setContext((_, { headers }) => {

        const transformedHeaders = token
            ? { ...headers, authorization: `Bearer ${token}` }
            : { ...headers };

        return {
            headers: transformedHeaders,
        };
    });

    const wsLink = new GraphQLWsLink(
        createClient({
           url: WS_API,
          connectionParams: {
            headers: {
              "content-type": "application/json",
              authorization: `Bearer ${token}`,
            },
          },
        })
      );

    // Łączenie linków w zależności od rodzaju operacji
    const splitLink = split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === "OperationDefinition" &&
                definition.operation === "subscription"
            );
        },
        wsLink,
        authLink.concat(httpLink)
    );

    const apolloClient = new ApolloClient({
        link: splitLink,
        cache: new InMemoryCache(),
    });

    return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
