import { gql } from "@apollo/client";

export const GET_OFFER_PUBLIC = gql`
    query GetOffer($offerId: uuid!) {
      getOffer(id: $offerId) {
        address
        date
        email
        name
        party_type_name
        party_type_id
        id
        phone
        surname
        status
      }
    }
`;
