import { useMemo } from 'react';
import {Status, Wrapper} from '@googlemaps/react-wrapper';

export type GoogleMapProps = Readonly<{
    apiKey: string;
    fallback: JSX.Element;
    mountPoint: JSX.Element;
}>;

export const makeRender =
    (fallback: JSX.Element, mountPoint: JSX.Element) =>
    (status: Status): JSX.Element => {
        switch (status) {
            case Status.FAILURE:
                throw Error('Google Maps failed to load');
            case Status.LOADING:
                return fallback;
            default:
                return mountPoint;
        }
    };

export const GoogleMap = ({apiKey, fallback, mountPoint}: GoogleMapProps): JSX.Element => {
    const render = useMemo(() => makeRender(fallback, mountPoint), [fallback, mountPoint]);

    return <Wrapper apiKey={apiKey} render={render} />
};

