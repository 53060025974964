import { gql } from "@apollo/client";

export const GET_PARTY_TYPES = gql`
    query GetPartyTypes($bandId: uuid!) {
      party_types(where: {band_id: {_eq: $bandId}}, order_by: {created_at: asc}) {
        id
        automatic_price
        survey_is_enabled
        is_active
        name
        party_days_length
        band {
          name
        }
      }
    }
`;

export const GET_PARTY_TYPES_MANAGER = gql`
    query GetPartyTypes($bandId: uuid!) {
      party_types(where: {band_id: {_eq: $bandId}}, order_by: {created_at: asc}) {
        id
        automatic_price
        add_link_to_contract_in_offer
        survey_is_enabled
        is_active
        name
        party_days_length
        band {
          name
        }
      }
    }
`;

export const GET_PARTY_TYPES_MANAGER_BY_PARTY_TYPE_ID = gql`
    query GetPartyTypes($partyTypeId: uuid!) {
      party_types(where: {id: {_eq: $partyTypeId}}, order_by: {created_at: asc}) {
        id
        automatic_price
        add_link_to_contract_in_offer
        survey_is_enabled
        is_active
        name
        party_days_length
        band {
          name
        }
      }
    }
`;
