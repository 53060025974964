import { Helmet } from 'react-helmet-async';
// @mui
import {
    Typography,
    Box,
    Card,
    Container,
    Divider,
    Grid,
    Stack,
    Paper,
    Button,
    RadioGroup,
    FormControlLabel,
    Radio,
    CircularProgress
} from '@mui/material';
import { useSnackbar } from "notistack";
import { useLocation, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { GET_OFFER_PUBLIC } from "../../api/gql/queries/GetOfferPublic";
import { GET_CONTRACT_TEMPLATE_BY_PARTY_TYPE } from "../../api/gql/queries/GetContractTemplateByPartyType";
import ContractPageSendDetails, {
    ContractPageSendDetailsFormValuesProps
} from "../../sections/contract/ContractPageSendDetails";
import { FILL_CONTRACT } from "../../api/gql/mutations/FillContract";
import { GET_SURVEY_TEMPLATE_BY_ID } from 'src/api/gql/queries/GetSurveyTemplatesById';
import { GET_SURVEY_PUBLIC } from 'src/api/gql/queries/GetSurveyPublic';
import FormProvider, { RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { UPDATE_SURVEY } from 'src/api/gql/mutations/UpdateSurvey';
// ----------------------------------------------------------------------

type ContractTemplateVariable = {
    id: string;
    key: string;
    value?: string;
}

type ContractTemplateVariableTransformed = Omit<ContractTemplateVariable, 'id'> & { contract_template_variable_id: string; }

const addFormValuesToContractTemplateVariables = (
    contractTemplateVariables: ContractTemplateVariable[],
    contractTemplateVariablesValues: { [id: string]: string }
): ContractTemplateVariableTransformed[] => {
    return contractTemplateVariables.map((variable: ContractTemplateVariable) => {
        const value = contractTemplateVariablesValues[variable.id];
        return {
            key: variable.key,
            contract_template_variable_id: variable.id,
            value,
        };
    });
};

const useQueryParams = () => {
    return new URLSearchParams(useLocation().search);
};

export default function SurveyPage() {
    const { enqueueSnackbar } = useSnackbar();

    const params = useParams();


    const methods = useForm<any[]>({
        //resolver: yupResolver(SurveysDeliverySettingsFormSchema),
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const { loading: surveyLoading, error: surveyError, data: surveyData } = useQuery(GET_SURVEY_PUBLIC, { variables: { id: params.surveyId } });

    const { loading: offerPublicLoading, error: offerPublicError, data: offerPublicData } = useQuery(GET_OFFER_PUBLIC, { 
        variables: { offerId: surveyData?.surveys[0]?.offer_id } ,
        skip: surveyLoading
    });

    const { loading: surveyTemplateLoading, error: surveyTemplateError, data: surveyTemplateData } = useQuery(GET_SURVEY_TEMPLATE_BY_ID, {
        variables: { id: surveyData?.surveys[0]?.survey_template_id },
        skip: surveyLoading
    });

    const [surveyElements, setSurveyElements] = useState<any[]>([]); // State to store the list of surveyElements
    const [isFirstLoadingTemplate, setIsFirstLoadingTemplate] = useState<boolean>(true);

    useEffect(() => {
        if (surveyData?.surveys[0]?.survey && !surveyLoading && isFirstLoadingTemplate) {
            setSurveyElements(JSON.parse(surveyData.surveys[0].survey));
            setIsFirstLoadingTemplate(false);
        } else if (surveyTemplateData?.survey_templates[0]?.template && isFirstLoadingTemplate && !surveyTemplateLoading) {
            setSurveyElements(JSON.parse(surveyTemplateData.survey_templates[0].template));
            setIsFirstLoadingTemplate(false);
        }
    }, [surveyElements, surveyTemplateData, surveyData]);


    //let jsonTemplate = ;

    // const { loading: getContractTemplateByPartyTypeLoading, error: getContractTemplateByPartyTypeError, data: getContractTemplateByPartyTypeData } = useQuery(GET_CONTRACT_TEMPLATE_BY_PARTY_TYPE, {
    //     variables: { partyTypeId: offerPublicData?.getOffer?.party_type_id },
    //     skip: offerPublicLoading
    // });

    const [updateSurvey] = useMutation(UPDATE_SURVEY, {
        // refetchQueries: [
        //     { query: GET_SURVEY_TEMPLATES, variables: { id: params.bandPartyTypeId } },
        //     'GetSurveyTemplates'
        // ],
    });

    const query = useQueryParams();
    const editable = query.get('editable');

    const onSubmit = async (formData: any[]) => {
        try {
            //TO DO CHECK DEADLINE
            let now = new Date();
            let lockAt = new Date(surveyData?.surveys[0]?.lock_edit_at);
            console.log(editable);
            if (now > lockAt && editable != 'true') {
                return enqueueSnackbar('Edycja Ankiety jest już zablokowana! Skontaktuj się z zespołem!', {
                    variant: 'error',
                    persist: false
                });
            }
            if (params.surveyId) {
                await updateSurvey({
                    variables: {
                        survey_id: params.surveyId,
                        survey: JSON.stringify(surveyElements)
                    }
                });
            } else {
                enqueueSnackbar('Wystąpił błąd skontaktuj się z zespołem!');
            }
            enqueueSnackbar('Pomyślnie zapisano ankietę!');
        } catch (error) {
            return enqueueSnackbar(error, {
                variant: 'error',
                persist: false
            })
        }    };

    const handleChangeTextField = (index: number, value: string) => {
        surveyElements[index].answer = value;
        setSurveyElements([...surveyElements]);
    }

    const handleChangeCheckbox = (index: number, indexOption: number, value: any) => {
        if (surveyElements[index].type === 'radio') {
            surveyElements[index].options.forEach((option: any, optionIndex: number) => {
                surveyElements[index].options[optionIndex].selected = false;
            })
        }
        surveyElements[index].options[indexOption].selected = value.target.checked;
        setSurveyElements([...surveyElements]);
    }

    return (
        <>
            <Helmet>
                <title> Party Manager | Ankieta</title>
            </Helmet>
            <Container style={{ height: '100%' }} maxWidth={'lg'}>
                <Stack justifyContent='center' alignItems="center"  >
                    <Card sx={{ p: 3, mb: 4 }}>
                        {(surveyLoading || surveyTemplateLoading) && <CircularProgress />}
                        {(!surveyLoading && !surveyTemplateLoading) ?
                            <>
                                <Grid container spacing={3}>
                                    <Grid item>
                                        <Typography variant="h3">
                                            Wypełnij Ankietę dotyczącą twojej imprezy
                                        </Typography>
                                        {(!offerPublicLoading && offerPublicData != null) ?
                                        <Typography variant="h6">
                                            Twoja impreza odbędzie się w dniu {offerPublicData?.getOffer?.date} w {offerPublicData?.getOffer?.address}
                                        </Typography> : null}
                                    </Grid>
                                </Grid>
                                <Divider />
                                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                    <Grid container spacing={3} marginTop={3}>
                                        {surveyElements.map((question: any, index: number) => {
                                            return (
                                                <Grid item xs={12}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" textAlign="left">
                                                                {question.question}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {question.type === 'textfield' && (
                                                                <RHFTextField name={""} type="text" label="Odpowiedź" defaultValue={question.answer}
                                                                    onChange={(e) => handleChangeTextField(index, e.target.value)} />
                                                            )}
                                                            {question.type === 'textarea' && (
                                                                <RHFTextField
                                                                    name=""
                                                                    label="Odpowiedź"
                                                                    defaultValue={question.answer}
                                                                    minRows={4}
                                                                    multiline
                                                                    onChange={(e) => handleChangeTextField(index, e.target.value)} />
                                                            )}
                                                            {question.type === 'checkbox' && (
                                                                <Stack direction="column">
                                                                    {question.options.map((option: any, optionIndex: number) => {
                                                                        return (
                                                                            <Box key={optionIndex} mb={2}> 
                                                                            <RHFCheckbox name={option.name} label={option.name} checked={option.selected}
                                                                                onClick={(e) => handleChangeCheckbox(index, optionIndex, e)} />
                                                                            </Box>
                                                                        )
                                                                    })}
                                                                </Stack>
                                                            )}
                                                            {question.type === 'radio' && (
                                                                <Stack direction="column">
                                                                    <RadioGroup
                                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                                        defaultValue="female"
                                                                        name="radio-buttons-group"
                                                                    >
                                                                        {question.options.map((option: any, optionIndex: number) => {
                                                                            return (
                                                                                <Box key={optionIndex} mb={2}> 
                                                                                <FormControlLabel value={option.name} control={<Radio />} label={option.name} checked={option.selected} onChange={(e) => handleChangeCheckbox(index, optionIndex, e)} />
                                                                                </Box>
                                                                            )
                                                                        })}
                                                                    </RadioGroup>
                                                                </Stack>
                                                            )}

                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })}
                                        <Grid item my={3}>
                                            {/*<Paper elevation={3} style={{ maxHeight: '70vh', overflow: 'scroll' }}>*/}
                                            {/*    /!* <div dangerouslySetInnerHTML={{__html: getContractTemplateByPartyTypeData?.party_types_by_pk?.contract_template?.template}} /> *!/*/}
                                            {/*</Paper>*/}
                                            <Box mt={3}>
                                                <LoadingButton type="submit" variant="contained" loading={isSubmitting} >
                                                    Zapisz Ankietę
                                                </LoadingButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </FormProvider>
                            </> : null
                        }
                    </Card>
                </Stack>
            </Container >
        </>
    );
}
