export const mount = (
    {
     center,
     zoom,
     fullscreenControl = false,
     streetViewControl = false,
     mapTypeControl = false,
     styles,
     ...options
    }: google.maps.MapOptions  = {},
    root: HTMLElement,
    enableMarkerControl = false,
    handleMarkerPositionChange?: (arg1: google.maps.LatLng) => google.maps.LatLng
): void => {
    const map = new google.maps.Map(root, {
        center,
        zoom,
        styles,
        fullscreenControl,
        streetViewControl,
        mapTypeControl,
        ...options
    })

   const marker = new google.maps.Marker({
        map,
        position: center,
    })

    enableMarkerControl && map.addListener("click", (e: any) => {
        placeMarkerAndPanTo(e.latLng, map);
        if(handleMarkerPositionChange) {
            handleMarkerPositionChange(e.latLng)
        }
    });



    function placeMarkerAndPanTo(latLng: google.maps.LatLng, map: google.maps.Map) {
        marker.setPosition( latLng );
        map.panTo(latLng);
    }
}
