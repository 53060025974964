import { gql } from "@apollo/client";

export const GET_SURVEY_PUBLIC = gql`
query getSurvey($id: uuid!) {
    surveys(where: {id: {_eq: $id}}) {
      id
      lock_edit_at
      send_email_at
      sent_email_at
      survey
      survey_template_id
      offer_id
      created_at
    }
  }
`;