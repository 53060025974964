import {useLocation, Outlet, useNavigate} from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
//
import Footer from './Footer';
import Header from './Header';
import {useEffect} from "react";

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();

  const isHome = pathname === '/';
  const navigate = useNavigate();

  useEffect(() => { // TODO: Get rid of this file instead of redirecting to dashboard
    navigate('/dashboard', { replace: true });
  })


  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      {/*<Header />*/}

      {/*<Box*/}
      {/*  component="main"*/}
      {/*  sx={{*/}
      {/*    flexGrow: 1,*/}
      {/*    ...(!isHome && {*/}
      {/*      pt: { xs: 8, md: 11 },*/}
      {/*    }),*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Outlet />*/}
      {/*</Box>*/}

      {/*<Footer />*/}
    </Box>
  );
}
