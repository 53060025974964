import { useRef, useEffect } from "react";

const options = {
    componentRestrictions: { country: "pl" },
    fields: ["formatted_address", "geometry"],
};

type AutoCompleteProps = {
    defaultValue?: string,
    placeholder?: string,
    getPlaceDetails: (formatted_address: string, lat: number, lng: number) => void
}

const AutoComplete = ({getPlaceDetails, defaultValue, placeholder}: AutoCompleteProps) => {
    const inputRef =  useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if(inputRef?.current) {
            const autocomplete = new window.google.maps.places.Autocomplete(
                inputRef.current,
                options
            );
            autocomplete.addListener("place_changed", async function () {
                const place = await autocomplete.getPlace();
                if (place?.formatted_address && place?.geometry?.location?.lat() && place?.geometry?.location?.lng()) {
                    getPlaceDetails(place.formatted_address, place?.geometry?.location?.lat(), place?.geometry?.location?.lng())
                }
            });
        }

    }, [getPlaceDetails]);

    return (
        <div>
            <input
                defaultValue={defaultValue}
                style={{
                    padding: "16.5px 14px",
                    width: "100%",
                    color: "#212B36",
                    borderRadius: "8px",
                    fontSize: "inherit",
                    fontFamily: "inherit",
                    borderWidth: "1px"
                }}
                placeholder= {placeholder ? placeholder : "Dokładny adres miejsca imprezy"}
                name="address"
                ref={inputRef} />
        </div>
    );
};
export default AutoComplete;
