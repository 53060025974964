import { gql } from "@apollo/client";

export const GET_BANDS = gql`
query getBandsCore($limit: Int! = 50) {
  bands(limit: $limit) {
    name
    id
    city
  }
}
`;