import {type ReactHTML, useEffect, useRef, createElement} from 'react';

type Options = Readonly<google.maps.MapOptions>;

export type MountGoogleMapsProps = Readonly<{
    readonly options: Options;
    readonly mount: (options: Options, mountPoint: HTMLElement, enableMarkerControl: boolean, handleMarkerPositionChange?: () => google.maps.LatLng) => void;
    readonly tag?: keyof ReactHTML;
    readonly enableMarkerControl?: boolean,
    readonly passMarkerPositionChange?: () => google.maps.LatLng
}>;

export const GoogleMapMountPoint = ({options, mount, tag = 'div', enableMarkerControl = false, passMarkerPositionChange}: MountGoogleMapsProps): JSX.Element => {
    const ref = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if(passMarkerPositionChange) {
            if (ref.current != null) mount(options, ref.current, enableMarkerControl, passMarkerPositionChange)
        } else if (ref.current != null) mount(options, ref.current, enableMarkerControl)
    }, [options, mount, ref]);

    return createElement(tag, {ref, className: 'google-maps-mount-point', style: {width: "100%", height: '350px'}})
};

